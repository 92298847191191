var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full"},[_c('div',{ref:_vm.SIDE_SCROLLER,class:[
			'w-full',
			{ 'flex overflow-x-auto flex-nowrap justify-start': !_vm.overrideDefaultStyles },
			_vm.itemSpacing,
			_vm.scrollerClass,
			'ease-in-out',
			_vm.duration,
			{ 'hide-horizontal-scrollbar': _vm.elasticScroll }
		],style:({transform: _vm.getTransform()}),on:{"&scroll":function($event){_vm.scrollSpied++},"touchstart":_vm.setInitialTouchPosition,"touchmove":_vm.getDragPosition,"touchend":_vm.resetPosition}},[_vm._t("default")],2),_vm._v(" "),(_vm.hasFade)?_c('WwFadedEdge',{class:[ _vm.fadeVisibility ],attrs:{"parent-ref-name":_vm.SIDE_SCROLLER,"scroll-spied":_vm.scrollSpied,"gradient-color-from":_vm.gradientColorFrom,"fade-use-row-item-height":_vm.fadeUseRowItemHeight,"gradient-width":_vm.gradientWidth}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }