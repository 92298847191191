<template>
	<div>
		<WwModal
			:modal-id="modalId"
			max-width="max-w-lg"
		>
			<template #header>
				<h2 class="absolute text-4xl font-bold top-2 left-6">
					Check-In
				</h2>
				<h5 class="w-full pt-5">
					Leave a review for
					<span class="font-bold">
						{{ strain.name }}
					</span>
				</h5>
			</template>
			<template #body>
				<form
					v-if="showReviewForm && isMounted === true"
					id="reviewForm"
					ref="reviewForm"
					v-require-login="{callback: handleCheckin}"
					class="flex flex-col py-2"
				>
					<div class="mb-1 text-sm font-bold uppercase">
						Review
					</div>
					<textarea
						v-model.trim="review"
						placeholder="How did you like this strain?"
						required
						class="mb-4 placeholder-gray-500 bg-gray-200 border-none rounded focus:ring focus:ring-green-300"
					/>
					<div
						v-if="strain.ratings"
						class="flex flex-wrap"
					>
						<div
							v-for="(ratingType, index) in ratingGroups"
							:key="index"
							:addRating="setZeroRating(ratingType)"
							class="w-full mb-4 md:w-1/3"
						>
							<div class="mb-1 text-sm font-bold uppercase">
								{{ ratingType.name }}
							</div>
							<StarRating
								v-if="isMounted"
								v-model="newRatings[ratingType.id]"
								:star-size="20"
								:padding="6"
								:show-rating="false"
							/>
						</div>
					</div>
				</form>
			</template>
			<template #footer>
				<button
					type="submit"
					form="reviewForm"
					:disabled="disabled"
					:class="disabled ? 'bg-green-300' : 'bg-green-500'"
					class="self-start w-full h-12 mt-2 text-lg font-bold text-white border-none rounded-lg md:w-32"
				>
					Check-In
				</button>
			</template>
		</WwModal>
	</div>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwModal from '@/components/UI/WwModal.vue'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'

export default {
	components: {
		WwModal,
		StarRating: () => import('vue-star-rating')
	},
	props: {
		modalId: {
			type: String,
			required: true
		},
		strain: {
			type: Object,
			required: true
		}
	},
	data: function() {
		return {
			review: '',
			ratingsArray: [],
			ratings: {},
			newRatings: {},
			showReviewForm: true,
			isMounted: false,
			ratingGroups: [
				{
					name: 'Aroma',
					id: 'ar'
				},
				{
					name: 'Aesthetics',
					id: 'ae'
				},
				{
					name: 'Potency',
					id: 'po'
				},
				{
					name: 'Flavor',
					id: 'fl'
				},
				{
					name: 'Effect',
					id: 'ef'
				}
			]
		}
	},
	computed: {
		disabled() {
			const tempArray = []

			for (const rating in this.newRatings) { // check that at least 1 field in star-rating has been entered
				if (this.newRatings[rating] == 0 || this.newRatings[rating] == undefined) {
					tempArray.push(this.newRatings[rating])
				}
			}
			if (tempArray.length == this.ratingsArray.length) {
				return true
			}

			if (this.review == '' || this.review == undefined || this.review == null) {
				return true
			}
			return false
		},
		formData() {
			return {
				id: this.strain.id,
				seo: this.strain.seo,
				review: this.review,
				ratings: this.newRatings
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		...mapActions('user', [
			'setStrainCheckin'
		]),
		...mapActions('auth', [
			'auth'
		]),
		setZeroRating(rating) { // set default rating as 0 so that we can pass all rating_types to api (previously would not pass a rating value of 0)
			if (this.ratingsArray.includes(rating) == false) {
				this.ratingsArray.push(rating)
				this.newRatings = Object.assign({}, this.newRatings, { [rating.id]: 0 })
			}
		},
		clearForm() {
			this.review = ''
			this.ratingsArray = []
		},
		async handleCheckin() {
			try {
				const checkinResponse = await this.setStrainCheckin(this.formData)
				if (checkinResponse.data.success) {
					this.showToast({
						primaryText: 'Success',
						secondaryText: 'Checkin saved!',
						type: SUCCESS
					})
					this.clearForm()
					this.$nextTick(() => {
						this.closeModal()
					})
				}
			} catch (error) {
				this.showToast({
					primaryText: 'Invalid',
					secondaryText: 'There was a problem. Please try again.',
					type: ERROR
				})
			}
		}
	}
}
</script>
