<template>
	<router-link
		:to="business.url"
		class="flex items-center justify-between p-3 mb-3 border border-gray-300 rounded shadow-sm flex-nowrap"
	>
		<div class="pr-3 h-13 w-13">
			<WwImage
				:src="business.image"
				classes="rounded-full overflow-hidden"
				:alt="business.name"
				:width="55"
				:height="55"
				:image-path="LISTING_IMAGES"
			/>
		</div>
		<div class="flex-1">
			<span
				class="font-bold"
				aria-hidden="true"
			>
				{{ business.name }}
			</span><br>
			<span class="text-xs">
				{{ numberOfMatchingProducts }}
			</span>
		</div>
		<div class="p-2 text-xs font-bold text-center border border-gray-300 rounded w-14 hover:bg-gray-100">
			<img
				src="@/assets/icons/star-full-yellow.svg"
				alt="Star"
				width="14"
				height="14"
				class="inline align-top"
			>
			{{ rating }}
		</div>
	</router-link>
</template>

<script async>
import { mapState } from 'vuex'

import WwImage from '@/components/multiUse/WwImage.vue'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'

export default {
	components: {
		WwImage
	},
	props: {
		business: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			showIcon: false,
			LISTING_IMAGES
		}
	},
	computed: {
		...mapState({
			cityUrl: state => state.meta.city_url || ''
		}),
		numberOfMatchingProducts() {
			const numMatching = this.business.items_matched
			if (numMatching == 1) {
				return numMatching + ' item'
			} else if (numMatching > 8) {
				return '9+ items'
			} else {
				return numMatching + ' items'
			}
		},
		rating() {
			return Math.round(this.business.review_average * 10) / 10
		}
	}
}
</script>
