<template>
	<div class="flex w-full">
		<div
			:ref="SIDE_SCROLLER"
			:class="[
				'w-full',
				{ 'flex overflow-x-auto flex-nowrap justify-start': !overrideDefaultStyles },
				itemSpacing,
				scrollerClass,
				'ease-in-out',
				duration,
				{ 'hide-horizontal-scrollbar': elasticScroll }
			]"
			:style="{transform: getTransform()}"
			@scroll.passive="scrollSpied++"
			@touchstart="setInitialTouchPosition"
			@touchmove="getDragPosition"
			@touchend="resetPosition"
		>
			<slot />
		</div>
		<WwFadedEdge
			v-if="hasFade"
			:class="[ fadeVisibility ]"
			:parent-ref-name="SIDE_SCROLLER"
			:scroll-spied="scrollSpied"
			:gradient-color-from="gradientColorFrom"
			:fade-use-row-item-height="fadeUseRowItemHeight"
			:gradient-width="gradientWidth"
		/>
	</div>
</template>

<script defer>
import SIDE_SCROLLER from '@/constants/UI/side-scroller.js'

export default {
	components: {
		WwFadedEdge: () => import('@/components/UI/WwFadedEdge.vue')
	},
	props: {
		overrideDefaultStyles: {
			type: Boolean,
			default: false
		},
		fadeVisibility: {
			type: String,
			default: ''
		},
		itemSpacing: {
			type: String,
			default: 'space-x-0'
		},
		scrollerClass: {
			type: String,
			default: ''
		},
		gradientColorFrom: {
			type: String,
			default: 'from-white'
		},
		gradientWidth: {
			type: Number,
			default: 20
		},
		fadeUseRowItemHeight: {
			type: Boolean,
			default: false
		},
		overscrollLimit: {
			type: Number,
			default: 75
		},
		elasticScroll: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			scrollSpied: 0,
			SIDE_SCROLLER,
			startPosition: 0,
			relativePosition: 0,
			duration: 'duration-0'
		}
	},
	computed: {
		hasFade() {
			return this.fadeVisibility !== 'hidden'
		}
	},
	methods: {
		isAtEnd() {
			return this.getScrollLeft() + this.getClientWidth() === this.getScrollWidth()
		},
		isAtBeginning() {
			return this.getScrollLeft() === 0
		},
		getClientWidth() {
			return this.$refs?.[SIDE_SCROLLER]?.clientWidth || 0
		},
		getScrollWidth() {
			return this.$refs?.[SIDE_SCROLLER]?.scrollWidth || 0
		},
		getScrollLeft() {
			return this.$refs?.[SIDE_SCROLLER]?.scrollLeft || 0
		},
		resetPosition() {
			if (this.isAtEnd() || this.isAtBeginning()) {
				this.duration = 'duration-200'
			}
			this.relativePosition = 0
			setTimeout(() => {
				this.duration = 'duration-0'
			}, 200)
		},
		setInitialTouchPosition(event) {
			const touchPosition = event.touches[0].clientX
			this.startPosition = touchPosition
		},
		getDragPosition(event) {
			const touchPosition = event.touches[0].clientX
			if (this.isAtEnd() || this.isAtBeginning()) {
				this.relativePosition = touchPosition - this.startPosition
			}
		},
		getTransform() {
			let xPosition = 0
			if (this.elasticScroll) {
				if (this.isAtBeginning()) {
					if (this.relativePosition > this.overscrollLimit) {
						xPosition = this.overscrollLimit
					} else {
						xPosition = this.relativePosition
					}
				}
				if (this.isAtEnd()) {
					if (this.relativePosition < (this.overscrollLimit * -1)) {
						xPosition = (this.overscrollLimit * -1)
					} else if (this.relativePosition < 0) {
						xPosition = this.relativePosition
					}
				}
			}
			return `translate3d(${xPosition}px, 0px, 0px)`
		}
	}
}
</script>
