<template>
	<div>
		<div
			v-if="isMobile && !showFiltersOnMobile"
			class="flex justify-start w-full pb-3"
		>
			<button
				class="flex items-center justify-between w-24 p-2 text-sm font-bold uppercase border border-gray-300 rounded shadow-sm h-9 focus:ring focus:ring-green-300 focus:border-gray-300"
				@click="toggleFiltersOnMobile"
			>
				<SettingsGear class="flex self-center" />
				Filters
			</button>
		</div>

		<div class="flex flex-col w-full min-w-36">
			<div
				v-if="isMobile && showFiltersOnMobile"
				class="flex w-full my-4 text-2xl font-bold text-center"
			>
				<img
					src="@/assets/icons/back2.svg"
					alt="Back"
					class="self-center h-4 cursor-pointer"
					@click="toggleFiltersOnMobile"
				>
				<div class="flex-1">
					Filters
				</div>
			</div>
			<div v-if="isMounted && !(isMobile && !showFiltersOnMobile)">
				<div
					class="text-sm font-bold uppercase"
					tabindex="0"
				>
					sort by
				</div>
				<select
					v-model="sortBy"
					class="w-full h-12 my-2 border-gray-300 rounded shadow-sm focus:ring focus:ring-green-300 focus:border-gray-300"
				>
					<option
						v-for="option in sortOptions"
						:key="option.value"
						:value="option.value"
					>
						{{ option.text }}
					</option>
				</select>

				<div
					class="py-2 text-sm font-bold uppercase"
					tabindex="0"
				>
					Strain Types
				</div>
				<div
					v-for="checkbox in checkboxes"
					:key="checkbox.text"
					class="flex flex-col text-sm"
				>
					<label class="mb-2">
						<input
							v-model="filterBy"
							:value="checkbox.value"
							class="w-5 h-5 text-green-500 border-2 border-gray-300 rounded-sm focus:ring focus:ring-green-300 focus:border-gray-300"
							type="checkbox"
							:checked="false"
						>
						<span class="align-middle px-2.5">
							{{ checkbox.text }}
						</span>
					</label>
				</div>
			</div>

			<div
				v-if="showFiltersOnMobile"
				class="py-4"
			>
				<button
					class="text-white bg-green-500 text-center align-middle h-12 border-transparent py-1.5 px-3 my-2 text-base rounded box-border uppercase w-full font-bold"
					@click="toggleFiltersOnMobile"
				>
					apply filters
				</button>
				<button
					class="text-black text-center align-middle h-12 border py-1.5 px-3 my-2 text-base rounded box-border uppercase w-full font-bold"
					@click="clearFilters"
				>
					clear
				</button>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import SettingsGear from '@/components/icons/SettingsGear.vue'
import { BEST_RATING, HYBRID, INDICA, MOST_POPULAR, SATIVA, TRENDING } from '@/constants/strainFilterOptions.js'

export default {
	components: {
		SettingsGear
	},
	props: {
		showFiltersOnMobile: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'toggle-filters-on-mobile', 'update-filters', 'load-more' ],
	data() {
		return {
			checkboxes: [
				HYBRID,
				INDICA,
				SATIVA
			],
			sortBy: 'most-popular',
			sortOptions: [
				MOST_POPULAR,
				BEST_RATING,
				TRENDING
			],
			isMounted: false,
			filterBy: []
		}
	},
	computed: {
		...mapGetters([
			'location', 'isMobile'
		]),
		formattedUrl() {
			let url = ''
			const filters = this.filterBy.join('+')
			const sortOrder = this.sortBy === 'most-popular' ? '' : this.sortBy

			if (filters.length) {
				url += `/${filters}`
			}
			if (sortOrder.length) {
				url += `/${sortOrder}`
			}

			return url
		}
	},
	watch: {
		isMobile(isMobile) {
			if (!isMobile && this.showFiltersOnMobile) {
				this.$emit('toggle-filters-on-mobile')
			}
		},
		sortBy() {
			this.handleFilterChange()
		},
		filterBy() {
			this.handleFilterChange()
		}
	},
	async mounted() {
		this.isMounted = true
		await this.$nextTick()
		this.initFilters()
	},
	methods: {
		initFilters() {
			const { path } = this.$route
			for (const optionName in this.sortOptions) {
				const option = this.sortOptions[optionName].value
				if (path.includes(option)) {
					this.sortBy = option
				}
			}

			this.checkboxes.forEach(checkbox => {
				if (path.includes(checkbox.value)) {
					this.filterBy.push(checkbox.value)
				}
			})
		},
		handleFilterChange() {
			if (this.isMounted) {
				this.$emit('update-filters', this.formattedUrl)
			}
		},
		clearFilters() {
			this.filterBy = []
			this.sortBy = 'most-popular'
		},
		toggleFiltersOnMobile () {
			this.$emit('toggle-filters-on-mobile')
		}
	}
}
</script>
