<template>
	<div
		v-if="showWrapper"
		class="fixed inset-0 z-40 overflow-y-auto"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<Transition name="bg-fade">
			<div
				v-if="showModal"
				:class="[
					'fixed inset-0 bg-black bg-opacity-75'
				]"
				aria-hidden="true"
				@click.prevent
			/>
		</Transition>
		<Transition :name="`slide-${transitionDirection}`">
			<div
				v-if="showModal"
				:class="[
					maxWidth,
					modalPosition,
					'flex justify-center h-full text-center mx-auto w-screen'
				]"
			>
				<div
					v-click-outside="handleClose"
					:class="[
						shadow,
						backgroundColor,
						modalPadding,
						modalMargin,
						modalHeight,
						modalBorderRadius,
						'inline-block align-middle w-full text-left overflow-hidden transform transition-all md:max-w-lg lg:max-w-3xl xl:max-w-3xl 2xl:max-w-4xl'
					]"
				>
					<slot
						name="close"
						:handle-hide-button-click="handleHideButtonClick"
					>
						<div
							v-if="!hasCloseSlot"
							class="absolute z-50 top-1 right-1"
						>
							<button
								type="button"
								class="flex items-center justify-center w-8 h-8 text-black transition-all duration-100 bg-white rounded-full ring-offset-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
								@click="handleHideButtonClick"
							>
								<span class="sr-only">
									Close panel
								</span>
								<CloseIcon />
							</button>
						</div>
					</slot>

					<div @click.stop>
						<div class="min-h-10">
							<slot
								name="header"
								:handle-hide-button-click="handleHideButtonClick"
							>
								<div
									v-if="!hasHeaderSlot && showPullDown"
									:class="[
										pullDownTransition ? 'scale-x-100' : 'scale-x-0',
										'absolute transition-all duration-500 transform left-1/2 -translate-x-1/2 -translate-y-1/2 top-1.5 h-1.5 rounded-full w-20 shadow-inner bg-green-500'
									]"
									@click="handleHideButtonClick"
								/>
								<slot name="title">
									<div
										v-if="!hasHeaderSlot"
										:class="[
											'absolute w-4/5',
											titleClasses
										]"
									>
										{{ title }}
									</div>
								</slot>
							</slot>
						</div>
						<div
							:ref="`${modalId}-body`"
							:class="[
								{ 'overflow-y-auto': !yOverflowHidden },
								'mx-auto'
							]"
							:style="maxHeightStyleString"
						>
							<slot
								name="body"
								:handle-hide-button-click="handleHideButtonClick"
							/>
						</div>
						<slot
							v-if="!hideFooter"
							name="footer"
							:handle-hide-button-click="handleHideButtonClick"
						>
							<div
								v-if="!hasFooterSlot"
								class="mt-2 sm:flex sm:flex-row-reverse"
							>
								<button
									type="button"
									:disabled="okDisabled"
									:class="[
										okTextColor,
										`bg-${okColor}-${shade}`,
										'mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm text-semibold'
									]"
									@click="handleOk"
								>
									{{ okTitle }}
								</button>
								<button
									v-if="!singleButton"
									type="button"
									:class="[
										hideTextColor,
										`bg-${hideColor}-${shade}`,
										'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm text-semibold'
									]"
									@click="handleHideButtonClick"
								>
									{{ hideTitle }}
								</button>
							</div>
						</slot>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script async>
import { mapMutations, mapState } from 'vuex'

import CloseIcon from '@/components/icons/CloseIcon.vue'

export default {
	components: {
		CloseIcon
	},
	props: {
		modalId: {
			type: String,
			required: true
		},
		shadow: {
			type: String,
			default: 'shadow-xl'
		},
		backgroundColor: {
			type: String,
			default: 'bg-white'
		},
		maxWidth: {
			type: String,
			default: 'w-full'
		},
		yOverflowHidden: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		hideTitle: {
			type: String,
			default: 'Cancel'
		},
		hideColor: {
			type: String,
			default: 'white'
		},
		hideTextColor: {
			type: String,
			default: 'text-black'
		},
		hideClosesModal: {
			type: Boolean,
			default: true
		},
		okTitle: {
			type: String,
			default: 'Ok'
		},
		okColor: {
			type: String,
			default: 'green'
		},
		okTextColor: {
			type: String,
			default: 'text-white'
		},
		okDisabled: {
			type: Boolean,
			default: false
		},
		hideFooter: {
			type: Boolean,
			default: false
		},
		singleButton: {
			type: Boolean,
			default: false
		},
		modalPosition: {
			type: String,
			default: 'items-center',
			validator (value) {
				return value.includes('items-center') ||
				value.includes('items-start') ||
				value.includes('items-end')
			}
		},
		modalPadding: {
			type: String,
			default: 'p-6'
		},
		modalMargin: {
			type: String,
			default: 'm-4'
		},
		modalHeight: {
			type: String,
			default: 'h-auto'
		},
		modalBorderRadius: {
			type: String,
			default: 'rounded-lg'
		},
		headerOffset: {
			type: Number,
			default: 235
		},
		headerClasses: {
			type: String,
			default: 'pb-6'
		},
		titleClasses: {
			type: String,
			default: 'text-lg font-bold truncate top-2 left-6'
		},
		disablePullDown: {
			type: Boolean,
			default: false
		},
		transitionDirection: {
			type: String,
			default: 'down',
			validator: (value) => {
				return [
					'up',
					'down',
					'top',
					'bottom',
					'left',
					'right'
				].includes(value)
			}
		}
	},
	emits: [
		'close-prevented',
		'ok',
		'hide'
	],
	data() {
		return {
			showWrapper: false,
			showModal: false,
			pullDownTransition: false
		}
	},
	computed: {
		cssVars() {
			return {
				'--max-height': this.headerOffset + 'px'
			}
		},
		...mapState('modal', [
			'isOpen',
			'activeModalId',
			'closePrevented'
		]),
		showPullDown() {
			return !this.disablePullDown && this.transitionDirection === 'bottom'
		},
		hasCloseSlot() {
			return !!this.$slots.close
		},
		hasFooterSlot() {
			return !!this.$slots.footer
		},
		hasHeaderSlot() {
			return !!this.$slots.header
		},
		shade() {
			return this.okDisabled ? '300' : '500'
		},
		maxHeightStyleString() {
			return !this.yOverflowHidden ? `max-height: calc(100vh - ${this.headerOffset}px);` : ''
		}
	},
	watch: {
		isOpen: {
			immediate: true,
			handler(newValue) {
				this.toggleShowState(newValue)
			}
		},
		showModal(newValue) {
			if (newValue) {
				setTimeout(() => {
					this.pullDownTransition = true
				}, 150)
			} else {
				this.pullDownTransition = false
			}
		}
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		toggleShowState(isOpen) {
			if (isOpen && this.modalId === this.activeModalId) {
				this.showWrapper = true
				this.$nextTick(() => {
					this.showModal = true
				})
			} else if (this.showModal) {
				this.showModal = false
				setTimeout(() => {
					this.showWrapper = false
				}, 300)
			}
		},
		handleHideButtonClick(event) {
			this.$emit('hide')
			if (this.hideClosesModal) this.handleClose(event)
		},
		handleClose(event) {
			event.stopPropagation()
			this.closePrevented ? this.$emit('close-prevented') : this.closeModal()
		},
		handleOk(event) {
			event.stopPropagation()
			this.$emit('ok')
		}
	}
}
</script>
