export const STRAIN = 'strain'
export const PRODUCT = 'product'
export const BLOG = 'blog'
export const BRAND = 'brand'
export const DISPENSARY = 'dispensary'
export const DELIVERY = 'delivery'
export const MENU = 'menu'
export const BUSINESS = 'business'
export const DOCTOR = 'doctor'
export const CBD = 'cbd'
export const LOCATION = 'location'
export const LOCATIONS = 'locations'
export const DEAL = 'deal'
export const LISTING = 'listing'
export const CURRENT_LOCATION = 'current location'
export const CATEGORY = 'category'
