<template>
	<div
		:class="[
			'max-w-6xl w-full px-4 xl:px-0 mx-auto text-left xl:pb-2',
			{ 'w-full': showFiltersOnMobile },
			{ 'pb-4 md:pb-2': !showLoadMore }
		]"
	>
		<h1
			v-if="showHeader"
			class="my-4 text-2xl font-bold"
		>
			Marijuana Strains
			<span v-if="hasLocation">
				in {{ location.name }}
			</span>
		</h1>

		<div class="w-full md:flex">
			<Filters
				class="w-full xl:mr-4 md:w-1/4 xl:w-1/6"
				:show-filters-on-mobile="showFiltersOnMobile"
				:is-mobile="isMobile"
				@toggle-filters-on-mobile="toggleFiltersOnMobile"
				@update-filters="handleFilterChange"
			/>

			<div
				v-if="!showFiltersOnMobile"
				class="flex items-start w-full md:w-3/4 xl:w-5/6"
			>
				<div
					v-if="strainList.length"
					class="w-full md:py-4"
				>
					<SearchResultList
						:items="strainList"
						:is-mobile="isMobile"
						:list-type="STRAINS_LIST_FULL"
						gap-classes="gap-6"
						margin-classes="mx-auto"
						grid-col-classes="grid-cols-2 xl:grid-cols-4"
					/>
					<div
						v-if="showLoadMore"
						class="flex justify-center w-full pt-4 mb-7"
					>
						<button
							class="w-full px-3 py-3 font-bold uppercase bg-white border border-gray-300 rounded shadow-md md:w-40"
							@click="handleLoadMore"
						>
							Load More
						</button>
					</div>
				</div>
				<div
					v-else
					class="w-full"
				>
					<p class="mt-10 text-center">
						Your search returned no results.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapState } from 'vuex'

import SearchResultList from '@/components/search/SearchResultList.vue'
import { STRAINS_LIST_FULL } from '@/constants/search/listTypes.js'
import { logError } from '@/utils/error-handling.js'
import Filters from '@/views/strain/components/Filters.vue'

export default {
	components: {
		SearchResultList,
		Filters
	},
	data() {
		return {
			showLoadMore: true,
			lastElement: '',
			observer: {},
			page: 1,
			perPage: 20,
			stopScroll: false,
			visible: true,
			STRAINS_LIST_FULL,
			showFiltersOnMobile: false,
			urlFilterString: ''
		}
	},
	computed: {
		...mapGetters([
			'location'
		]),
		filters() {
			const reg = /\/|\+/g
			return this.urlFilterString && this.urlFilterString.replace(reg, ',')
		},
		formattedLocation() {
			const reg = /\/|\+/g
			return this.location.url.substring(1).replace(reg, ',')
		},
		hasLocation() {
			return this.$route.path.includes(this.location?.url)
		},
		...mapGetters([ 'isMobile' ]),
		geoData: function () {
			return this.$store.state
		},
		...mapState({
			strainList: state => state?.strain?.strains || [],
			pageCount: state => state?.strain?.pageCount || 0
		}),
		hasLastElement() {
			return this.isElement(this.lastElement)
		},
		showHeader() {
			return !(this.isMobile && this.showFiltersOnMobile)
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		}
	},
	watch: {
		strainList() {
			if (this.strainList.length < this.perPage) {
				this.showLoadMore = false
			}
			if (this.strainList.length && !this.showLoadMore) {
				const scrollTargets = document.getElementsByClassName('observe')
				this.lastElement = scrollTargets[scrollTargets.length - 5]
			}
		},
		lastElement(_, oldVal) {
			if (this.hasLastElement && !oldVal) {
				this.startObserver()
			} else {
				this.changeObserver(this.lastElement)
			}
		}

	},
	methods: {
		...mapActions('strain', [ 'PAGE' ]),
		fetchNextPage() {
			let endpoint = 'strains'
			if (this.$route.params.city || this.$route.params.state) {
				endpoint += `/city${this.location.url}`
			}
			if (this.filters === '' || this.filters === undefined) {
				return {
					endpoint: endpoint,
					params: {
						page: this.page
					}
				}
			} else {
				return {
					endpoint: endpoint,
					params: {
						page: this.page,
						filters: this.filters
					}
				}
			}
		},
		isElement(obj) {
			return obj instanceof HTMLElement
		},
		handleLoadMore() {
			this.fetchMoreStrains()
			this.showLoadMore = false
		},
		observerCallback(entries, observer) {
			if (entries[0].isIntersecting) {
				observer.unobserve(entries[0].target)
				this.fetchMoreStrains()
			}
		},
		startObserver() {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer = new IntersectionObserver(this.observerCallback)
				this.observer.observe(this.lastElement)
			}
		},
		changeObserver(newElement) {
			if (this.observer && this.hasLastElement) {
				this.observer.observe(newElement)
			}
		},
		async fetchMoreStrains() {
			if (this.page < this.pageCount) {
				this.page++

				try {
					// check for location in filters and remove
					if (!this.location?.url) return
					const locationUrl = this.formattedLocation
					if (this.filters.includes(`${locationUrl},`)) {
						this.filters = this.filters.replace(`${locationUrl},`, '')
					} else if (this.filters.includes(`${locationUrl}`)) {
						this.filters = this.filters.replace(`${locationUrl}`, '')
					}
					const response = await this.$store.dispatch('newGet', this.fetchNextPage())
					this.PAGE(response.data.results)
				} catch (e) {
					logError(e)
				}
			}
		},
		toggleFiltersOnMobile () {
			this.showFiltersOnMobile = !this.showFiltersOnMobile
		},
		handleFilterChange(filtersString) {
			this.urlFilterString = filtersString
			this.page = 1
			let path = '/strains'
			if (this.$route.params.city || this.$route.params.state) {
				path = this.location.url + path
			}
			this.$router.push({ path: path + filtersString, params: { page: this.page } })
			this.showLoadMore = true
		}
	}
}
</script>
