export const MOST_POPULAR = { value: 'most-popular', text: 'Most Popular' }
export const BEST_RATING = { value: 'best-rating', text: 'Best Rating' }
export const TRENDING = { value: 'trending', text: 'Trending' }

export const HYBRID = {
	value: 'hybrid', text: 'Hybrid'
}
export const INDICA = {
	value: 'indica', text: 'Indica'
}
export const SATIVA = {
	value: 'sativa', text: 'Sativa'
}
