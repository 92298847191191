<template>
	<div>
		<div
			v-if="strain && Object.keys(strain).length > 0"
			class="w-full max-w-6xl px-4 mx-auto product-wrap"
		>
			<div class="flex flex-wrap">
				<div
					id="breadcrumb"
					class="flex w-full breadcrumbs"
				>
					<nav aria-label="breadcrumb">
						<ol class="flex space-x-2 breadcrumb">
							<li class="breadcrumb-item">
								<router-link to="/">
									Home
								</router-link>
							</li>
							<li class="breadcrumb-item">
								<router-link to="/strains">
									Strains
								</router-link>
							</li>
							<li
								v-if="hasLocation"
								class="breadcrumb-item"
							>
								<router-link
									:to="location.url + '/strains'"
								>
									{{ location.name }}
								</router-link>
							</li>
							<li
								v-if="strain && strain.seo"
								class="breadcrumb-item active"
							>
								<router-link :to="strain.seo">
									{{ strain.name }}
								</router-link>
							</li>
							<li
								class="sr-only breadcrumb-item"
								aria-current="page"
							>
								{{ strain.name }}
							</li>
						</ol>
					</nav>
				</div>
				<div class="w-full py-3 md:w-1/3 md:pr-3">
					<WwImage
						:src="image"
						classes="border border-gray-300 shadow-sm rounded-xl"
						:alt="strain.name + ' image'"
						:fallback-image="DEFAULT_LEAF_IMAGE"
						:height="348"
						:width="348"
						:image-path="MENU_IMAGES"
						fetch-priority="high"
					/>
				</div>
				<StrainCheckinModal
					:modal-id="STRAIN_CHECKIN_MODAL"
					:strain="strain"
				/>
				<div class="w-full md:w-2/3">
					<div class="flex flex-wrap">
						<h1
							class="w-full lg:w-1/2 md:w-1/2"
							tabindex="0"
						>
							{{ strainName }}
						</h1>
						<div class="w-full lg:w-1/2 md:w-1/2">
							<button
								id="checkin-btn-m-l"
								class="hidden w-full h-12 ml-auto text-lg font-bold text-white bg-green-500 border-none rounded md:block md:w-32"
								@click.prevent="showModal(STRAIN_CHECKIN_MODAL)"
							>
								Check-In
							</button>
						</div>
					</div>

					<h2
						class="strain-type"
						tabindex="0"
					>
						{{ strain.type }}
					</h2>
					<div
						class="flex items-center pb-2 pr-2 text-black"
						tabindex="0"
					>
						<StarRating
							v-if="isMounted == true"
							:read-only="true"
							:rating="parseFloat( strain.ratings.overall )"
							:increment="0.1"
							:star-size="15"
							:padding="6"
							:show-rating="false"
							class="pb-1"
						/>
						<span>
							{{ parseFloat( strain.ratings.overall ) }}
						</span>
					</div>
					<div class="md:hidden">
						<button
							id="checkin-btn-s"
							class="h-10 text-base font-bold text-white bg-green-500 border-none rounded w-28"
							@click.prevent="showModal(STRAIN_CHECKIN_MODAL)"
						>
							Check-In
						</button>
					</div>
					<p
						class="desc"
						tabindex="0"
					>
						{{ strain.description }}
					</p>
					<h2
						id="find"
						class="mt-4"
						tabindex="0"
					>
						Find {{ strainName }} near {{ geoLocation }}
					</h2>
					<div class="flex flex-wrap mb-4">
						<div class="w-full">
							<div class="flex flex-wrap py-4">
								<div class="w-full">
									<div v-if="businesses.length > 0">
										<StrainBusinessCard
											v-for="(business, index) in businesses"
											:key="index"
											:business="business"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<h2
				class="pt-4 mt-4"
				tabindex="0"
			>
				No Strain Data
			</h2>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import WwImage from '@/components/multiUse/WwImage.vue'
import StrainCheckinModal from '@/components/UI/modals/CheckinModal.vue'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { STRAIN_CHECKIN_MODAL } from '@/constants/modal/names.js'
import { DEFAULT_LEAF_IMAGE } from '@/constants/search/defaultImage.js'

import StrainBusinessCard from './components/BusinessCard.vue'

export default {
	components: {
		StarRating: () => import('vue-star-rating'),
		StrainBusinessCard,
		StrainCheckinModal,
		WwImage
	},
	data() {
		return {
			isMounted: false,
			DEFAULT_LEAF_IMAGE,
			MENU_IMAGES,
			STRAIN_CHECKIN_MODAL
		}
	},
	metaInfo () {
		return {
			title: `${this.strain?.name} Strain Information & Reviews | Where's Weed`
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		...mapGetters([ 'location' ]),
		...mapState({
			strain: state => state.strain?.strain || {},
			businesses: state => state.strain?.businesses || {}
		}),
		geoLocation() {
			return this.location?.name || 'you'
		},
		strainName() {
			return this.strain?.name ? this.strain.name : 'No Name'
		},
		image() {
			return this.strain?.image
		},
		hasLocation() {
			return !!this.$route.params?.city
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapActions('user', [ 'strainCheckin' ]),
		...mapActions('auth', [ 'auth' ])
	}
}
</script>

<style lang="scss" scoped>
.product-wrap {
	text-align: left;
	.pic {

	}
	.pic-wrap {
		img {
			width: 100%;
		}
	}
	h1 {
		font-size: 1.75em;
	}
	h2 {
		color: $gray1;
		font-size: 1.5em;
		&.strain-type {
			font-size: 1em;
			color: $gray3;
		}
	}
	.desc {
		margin: 10px 0;
		color: $gray3;
	}
	.cta {
		margin: 10px 0;
		a {
			display: inline-block;
			font-size: 1.25em;
			padding: 5px 20px;
			text-transform: uppercase;
			border-radius: 6px;
			border: 1px solid $primary1;
			text-align: center;
			width: 100%;
			transition: all 0.2s ease-in-out;
			&:hover {
				background: $primary1;
				color: white;
				text-decoration: none;
				transition: all 0.2s ease-in-out;
			}
		}
	}
	.share {
		label {
			color: $gray3;
			font-size: 0.85em;
		}
		.btn-faded-primary {
			width: 31px;
			height: 31px;
			background: #e7f4fd;
			color: #36a2eb;
			svg {
				fill: #36a2eb;
				position: relative;
				left: -2px;
			}
		}
	}
	.locations {
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			width: 48%;
			margin-bottom: 5px;
			a {
				display: inline-block;
				width: 100%;
				font-size: 1.15em;
			}
		}
	}
	.overall-rating {
		svg {
			fill: #ffd055;
		}
	}
}
#business_list {
	text-align: left;
	h1 {
		font-size: 1.5em;
		padding: 10px 50px 10px 0 !important;
	}
}
.business-card-list {
	text-align: left;
	scroll-snap-type: mandatory;
	scroll-padding: 4vw;
	a {
		color: #444;
		&:hover {
			text-decoration: none;
		}
	}
	h1 {
		font-size: 1.5em;
	}
	background: white;
}
.breadcrumb {
	background: white;
	padding: 15px 0 0;
	a {
		color: $primary2;
	}
	.active {
		a {
			color: $primary1;
			font-weight: 600;
		}
	}
	.breadcrumb-item + .breadcrumb-item::before {
		content: ">";
	}
}
.view-more {
	display: inline-block;

	padding: 5px 10px;
	color: $primary3;
	font-weight: 600;
	font-size: 0.9em;
	cursor: pointer;
	&:hover {
		color: $primary1;
	}
}
</style>
