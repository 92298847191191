<template>
	<DefaultLayout>
		<router-view id="StrainShell" />
	</DefaultLayout>
</template>

<script async>
import { mapActions } from 'vuex'

import DefaultLayout from '@/layouts/default/Index.vue'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		DefaultLayout
	},
	watch: {
		'$route' () {
			this.fetchData(this.$route)
		}
	},
	serverPrefetch() {
		this.fetchData(this.$route)
	},
	mounted () {
		this.fetchData(this.$route)
	},
	methods: {
		fetchData: async function(to) {
			try {
				const response = await this.$store.dispatch('newGet', this.getPostData(to))
				if (response.data?.results) {
					this.SET(response.data.results)
				}
				return response
			} catch (e) {
				logError(e)
			}
		},
		getPostData: function (to) {
			const strain = to.params.strain
			let page = 1
			const strainsUrl = 'strains'

			if (to.params.page == '' || to.params.page == undefined) {
				page = 1
			} else {
				page = to.params.page
			}

			const reg = /\/|\+/g
			let filters = to.path.substring(1).replace(reg, ',')

			const tempScrubber = [] // the scrubber removes any unwanted params from the filter string so we only pass the info we need to the api.
			tempScrubber.push(to.params.city, this.$route.params.city, to.params.state, this.$route.params.state, to.params.strain, strainsUrl) // add any params we want to get rid of.

			let filterScrubber = tempScrubber.filter(e => e != null) // remove any nullish values (i.e. null and undefined)
			filterScrubber = filterScrubber.filter(function(item, pos, self) { // remove duplicates
				return self.indexOf(item) == pos
			})

			for (let i = 0; i < filterScrubber.length; i++) {
				const scrub = filterScrubber[i]
				if (filters.includes(`${scrub},`)) { // check for both the name with a comma
					filters = filters.replace(`${scrub},`, '')
				} else if (filters.includes(`${scrub}`)) { // and without a comma
					filters = filters.replace(`${scrub}`, '')
				}
			}

			if (to.name == 'strainIndex' || to.name == 'strainIndexA') {
				if (filters == '' || filters == undefined) {
					return {
						endpoint: `${strainsUrl}`,
						params: { // pass accepted params
							page: page
						}
					}
				} else {
					return {
						endpoint: `${strainsUrl}`,
						params: { // pass accepted params
							page: page,
							filters: filters
						}
					}
				}
			} else if (to.name == 'strainIndividual') { // for IndividualStrain.vue just pass the strain name in the url
				return {
					endpoint: `${strainsUrl}/` + strain
				}
			} else { // city ALL strains
				// define base path for endpoint
				let endpoint = `${strainsUrl}/`

				// add strain to api endpoint if avail
				if (to.params.strain) {
					endpoint += `${strain}/`
				}

				// add city path to endpoint
				endpoint += 'city/'

				// check if to route has state
				if (to.params.state) {
					endpoint += `${to.params.state}/`
				}

				endpoint += to.params.city
				if (filters == '' || filters == undefined) {
					return {
						endpoint: endpoint,
						params: { // pass accepted params
							page: page
						}
					}
				} else {
					return {
						endpoint: endpoint,
						params: { // pass accepted params
							page: page,
							filters: filters
						}
					}
				}
			}
		},
		...mapActions('strain', [ 'SET' ])
	}
}
</script>
