// these list types are retured by the api
export const STRAINS_LIST = 'strains'
export const MENUS_LIST = 'menus'
export const BUSINESSES_LIST = 'businesses'
export const LOCATIONS_LIST = 'locations'
export const BLOGS_LIST = 'blogs'
export const DEALS_LIST = 'deals'
export const CATEGORY_LIST = 'categories'
export const BRANDS_LIST = 'brands'
export const CHAIN_LIST = 'chain'

// these list types are assigned to each result in SearchResultList.vue
export const GLOBAL_LIST = 'global' // used in Search.vue for global results page
export const STRAINS_LIST_FULL = 'strains with all data'
export const STRAINS_LIST_COMPACT = 'strains with no business or rating data'
